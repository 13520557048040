import React, { useEffect, useLayoutEffect } from "react";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap-extended/lib/bootstrap-extended.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "./Components/style.css";
import "./Components/Footer.css";
import "./Components/Info_Section.css";
import "./Components/Input_fields.css";
import "./Components/Button.css";
import "./App.css";
import $ from "jquery";

import { Route, Routes, useLocation } from "react-router-dom";
import NoInternet from "./Components/NoInternet";

// import HeaderUser from "./Components/HeaderUser";

import axios from "axios";

// import RegisterForm from "./Components/RegisterForm";
// import TestOurTech_thankyou from "./Components/TestOurTech_thankyou";
import PrivacyPolicy from "./Components/PrivacyPolicy";

import NotFound from "./Components/NotFound";

function App() {
  const locat = useLocation();

  useEffect(() => {
    if (locat.pathname.match(/\/{2,}/)) {
      window.location.href = "/";
    }

    const interceptor = axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 429) {
        }

        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, []);

  useLayoutEffect(() => {
    localStorage.setItem("isVoucher", false);
    const backToTop = () => {
      return (
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          600
        ),
        !1
      );
    };
    backToTop();
    if (
      locat.pathname === "/privacy-policy"
      // locat.pathname === "/" ||
      // locat.pathname === "/register/registration-thankyou"
    ) {
      var element = document.getElementById("root");
      element.classList.add("img-bg");
    }
  }, [locat.pathname]);

  return (
    <NoInternet>
      {/* {locat.pathname === "/" ||
      locat.pathname === "/privacy-policy" ||
      locat.pathname === "/register/registration-thankyou" ? (
        <HeaderUser />
      ) : null} */}

      <Routes>
        {/* <Route path="/" element={<RegisterForm />} />

        <Route
          path="/register/registration-thankyou"
          element={<TestOurTech_thankyou />}
        /> */}

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      {locat.pathname.match(/\/{2,}/) ? (
        <div className="fix-white"></div>
      ) : null}
    </NoInternet>
  );
}

export default App;
